<template>
    <div class="blogs-landing">
        <HeroBasic :heading="title" accentColor="grey" class="hero" :showTopographic="true" :hasHeaderOffset="true" />

        <div class="results">
            <BlogListItem class="blog" v-for="blog in blogs" :blog="blog" :key="blog.title" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
    },
    blogs: {
        type: Array,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Blogs Landing',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.results {
    .blog {
        padding: 5rem 0;
    }
    .blog:not(:last-child) {
        border-bottom: 1px solid color(noir, default, 0.08);
    }
}
</style>
